
import { computed, defineComponent, ref, onMounted, onBeforeMount, watchEffect, getCurrentInstance } from "vue";
import { themeName, demo } from "@/core/helpers/documentation";

import CustomerService from "@/core/services/car/CustomerService";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import AddTaskModal from "@/components/modals/forms/AddTaskModal.vue";
import TaskService from "@/core/services/car/TaskService";
import { ct } from '@/store/stateless/store';

interface DeleteTaskBodyParam {
  task_id: number;
}
interface TaskBodyParam {
  customer_id: number;
  lead_id: number;
  due_date: string;
  disposition_id: number;
  task_notes: string;
  agent: number;
  lost_lead_reason_id: number;
}
export default defineComponent({
  name: "llt-explore",
  components: {
    AddTaskModal,
  },
  props: {
    taskDetails: Array,

    customer: Object,
    leadDetails: Array,
    lead: {
      type: Object,
      required: false
    },
    leads: {
        type: Object,
        required: true
    },
  },
  data() {
    return {
      color: ["success", "primary", "warning", "danger", "info"],
    };
  },
  setup() {
    let isActive = false;

    let deleteTaskBodyParam = {} as DeleteTaskBodyParam;
    const store = useStore();
    const route = useRoute();
    let taskParameters = {} as TaskBodyParam;
    onMounted(() => {
      //
    });
    const getLeadTaskList = (customer_id, lead_id) => {
      taskParameters.customer_id = customer_id;
      taskParameters.lead_id = lead_id;
      TaskService.getLeadTaskList(taskParameters);
    };
    const assignDeleteTaskValue = (task_id) => {
      deleteTaskBodyParam.task_id = task_id;
      // console.log(deleteTaskBodyParam);
    };
    const deleteTask = () => {
      TaskService.deleteTask(deleteTaskBodyParam);
    };
    const showAction = () => {
      //
    };
    const showTaskDom = computed(() => {
      return store.getters.getTaskDomStatus;
    });
    
    const closeModal = (e) => {
      if(e.which === 27) {
        closeRef.value.click()
        ct.isOpen = false
        ct.fp = false
      }
    }

    const closeRef = ref()

    onBeforeMount(() => {
      window.addEventListener('keyup', closeModal);
    })

    const inst = getCurrentInstance()

    watchEffect(() => {
      if(ct.isOpen) {
        setTimeout(() => {
          const closeTaskRef:any = inst?.refs.closeTaskRef
          if(closeTaskRef){
            closeTaskRef.forEach(x => x.focus())
          }
        }, 1000);
      }
    })

    const toFirst = () => {
      setTimeout(() => {
          const closeTaskRef:any = inst?.refs.closeTaskRef
          closeTaskRef.forEach(x => x.focus())
        }, 1000);
    }

    const notOmMAs = computed(() => {
      const lsUser = window.localStorage.getItem('user')
      if(lsUser) {
        const user = JSON.parse(lsUser)
        if(![7, 11, 15].includes(user.role_id)) return true
      }

      return false
    })

    return {
      notOmMAs,
      toFirst,
      ct,
      closeRef,
      closeModal,
      assignDeleteTaskValue,
      deleteTask,
      showAction,
      getLeadTaskList,
      showTaskDom,
      isActive,
    };
  },
});
